import React, { useEffect, useLayoutEffect, useState } from "react";
import styles from "./MainPage.module.css";
import { Header, Sidebar } from "components/layout";
import { MenuItem } from "react-pro-sidebar";
import { Logout, AdminPanelSettings, TextSnippetOutlined, AccountCircleOutlined,
    TabletAndroidOutlined, AppsOutlined, MenuOutlined} from "@mui/icons-material";
import { Routes, Route, NavLink, Outlet, useLocation } from "react-router-dom";
import { useGetAppsQuery } from "services/data/DataService";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "store/auth/authSlice";
import { FullScreenLoader } from "components/loaders";
import DevicePage from "../DevicePage";
import ProfilePage from "../ProfilePage";
import { FullScreenError } from "components/errors";
import { IconButton } from "components/buttons";
import { useNavigate } from 'react-router-dom';
import AppPage from "../AppPage";
import LicencePage from "../LicencePage";
import { SearchBar } from "components/forms";
import ToolbarManager from "../ToolbarManager";



const Profile = ({setActiveToolbar}) => {
    useEffect( () => {
        setActiveToolbar('profiles');
    }); 
    
    return (
        <h1 className="header">Профиль</h1>
    );
};

const RangePage = ({setActiveToolbar}) => {
    useEffect( () => {
        setActiveToolbar('ranges');
    }); 
    
    return (
        <h1 className="header">Диапазоны заводских номеров</h1>
    );
};




const MainPage = () => {

    const [collapsedBar, setCollapsedBar] = useState(false);
    const [activeToolbar, setActiveToolbar] = useState(null);

    const {userInfo, accessToken, rememberUser, error} = useSelector(state => state.auth);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => { 
        if(error || (!accessToken && !rememberUser)) {
            dispatch(logout());
        }
    }, [accessToken]);

    
    const onLogout = () => {
        dispatch(logout());
    };

    const onAdmin = () => {
        navigate("/admin");
    };

    /*if(error) {
        return <FullScreenError />;
    }*/

    if (!accessToken) {
        return <FullScreenLoader />;
    }
    
    const headerItems =
        <>
            {userInfo.roles.includes('admin') ?
                <IconButton
                    text="Перейти в Админку"
                    iconCmp={<AdminPanelSettings />}
                    handler={onAdmin}
                /> : null}
            <IconButton
                text="Выйти из кабинета"
                iconCmp={<Logout />}
                handler={onLogout}
            />
        </>;

    return (
        <div className="wrapper">
            <Sidebar
                collapsed={collapsedBar}    
            >
                <MenuItem
                    icon={<MenuOutlined />}
                    onClick={ () => setCollapsedBar(!collapsedBar) }
                >
                    <h1>Кабинет</h1> 
                </MenuItem>
                <MenuItem 
                    icon={< TabletAndroidOutlined />}
                    component={<NavLink to="devices" className="link" />}
                >
                    Кассы
                </MenuItem>
                <MenuItem
                    icon={< AppsOutlined />}
                    component={<NavLink to="apps" className="link" />}
                >
                    Приложения
                </MenuItem>
                <MenuItem 
                    icon={< AccountCircleOutlined/>}
                    component={<NavLink to="profiles" className="link" />}
                > 
                    Профили 
                </MenuItem>
                <MenuItem 
                    icon={< AccountCircleOutlined/>}
                    component={<NavLink to="ranges" className="link" />}
                > 
                    Заводские номера 
                </MenuItem>
                <MenuItem
                    icon={< TextSnippetOutlined />}
                    component={<NavLink to="licences" className="link" />}
                >
                    Лицензии
                </MenuItem>
            </Sidebar>
            <div className={styles.contentWrapper}>
                <Header
                    search={<ToolbarManager type={activeToolbar}/>}
                    user={userInfo?.userName}
                    items={headerItems}
                />
                <section className={styles.content}>
                    <Routes>
                        <Route path="devices" element={<DevicePage setActiveToolbar={setActiveToolbar}/>} />
                        <Route path="profiles" element={<ProfilePage setActiveToolbar={setActiveToolbar} />} />
                        <Route path="apps" element={<AppPage setActiveToolbar={setActiveToolbar}/>} />
                        <Route path="ranges" element={<RangePage setActiveToolbar={setActiveToolbar}/>} />
                        <Route path="licences" element={<LicencePage setActiveToolbar={setActiveToolbar} />} />
                    </Routes>
                </section>
            </div>
        </div>
        
    );
};

export default MainPage;