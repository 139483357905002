import {  Box, Card, CardActions, CardContent, ListItem, ListItemButton, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import styles from './AppItem.module.css';
import { mainUrl } from "services/data/DataService";

const AppItem = ({ selected, id, name, package: packageName, version, build, onSelect,
                    minSdk, targetSdk, abi, support, signedFor, icon, profile}) => {

                        

    const profileRenderer = (profile) => {
        return profile && profile.length ? 
                    <Typography className={styles.profileContent}>
                        Находится в профиле {profile.map( (item) => <span key={item} className={styles.profileItem}>{item}</span>)}
                    </Typography>

                : null;
    }                        

    return (
        <ListItemButton
            className={styles.app}
            sx={{pr:1, pb: 1, pl:0, pt: 0, flexGrow: 1}}
            disableRipple
            disableTouchRipple
            selected={selected}
        >
            <Card sx={{width:'100%'}} >
                <CardContent 
                  sx={{p:1}}  
                  onClick={(e) => onSelect({id, name})}>
                    <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'flex-start'}}>
                        <img className={styles.appicon}
                            src={`${mainUrl}${icon}`}
                            alt="App Icon"/>
                        <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                            <Box sx={{display: 'flex', flexDirection: 'column'}}>
                                <Box sx={{display: 'flex', height: '40px'}}>
                                    <div className={styles.name}>{name}</div>
                                    <div className={styles.versionWrapper}>
                                        <div className={styles.version}>Версия {version}</div>
                                        <div className={styles.build}>Сборка {build}</div>    
                                    </div>
                                </Box>
                                <Typography variant="caption">{packageName}</Typography>
                            </Box>
                            <Box sx={{display: 'flex', flexDirection: 'column'}}>
                                
                            </Box>
                        </Box>
                    </Box>
                </CardContent>
                <CardActions
                  className={styles.profileContainer}
                  sx={{display: 'flex', justifyContent: 'center'}}>
                    {profileRenderer(profile)}
                </CardActions>
                
            </Card>
        </ListItemButton>
    );
};

export default AppItem;