import React, { useEffect } from "react";

const ProfilePage = ({setActiveToolbar}) => {

  useEffect( () => setActiveToolbar("profile"));
  
  return (
    <>Profiles page!</>
  );
};

export default ProfilePage;