import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    selectedApp: null,
    activeDetails: null,
    pagination: {
        start: 0,
        limit: 10,
        page: 1
    }
};

const appSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        setSelectedApp: (state, {payload}) => { state.selectedApp = payload; },
        setActiveDetails: (state, {payload}) => { state.activeDetails = payload; },
        setPagination: (state, {payload}) => { state.pagination = payload; },
        resetApp: (state) => { state.selectedApp =  null; },
        resetActiveDetails: (state) => { state.activeDetails = null; },
        resetPagination: (state) => { state.pagination = {start: 0, limit: 10, page: 1}; }
    }
});

export const {
    setSelectedApp,
    setPagination,
    setActiveDetails,
    resetApp,
    resetActiveDetails,
    resetPagination
    
} = appSlice.actions;

export default appSlice.reducer;