import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    licenceKabinet: {},
    deviceKabinet: {},
    appKabinet: {},
    profileKabinet: {},
};

const searchbarSlice = createSlice({
    name: 'searchbar',
    initialState,
    reducers: {
      setLicenceKabinet: (state, {payload}) => {
        state.licenceKabinet = payload;            
      },
      resetLicenceKabinet: (state) => {
        state.licenceKabinet = {};
      },
      setdeviceKabinet: (state, {payload}) => {
        state.deviceKabinet = payload;         
      },
      resetdeviceKabinet: (state, {payload}) => {
        state.deviceKabinet = {};            
      },
      setKabinetSearchBar: (state, {payload}) => { 
        state[`${payload.active}Kabinet`] = payload.data;
      },
      setKabinetSearch: (state, {payload: {active, key, value}}) => { 
        state[`${active}Kabinet`][key] = value;
      }
    }
});

export const {
  setLicenceKabinet,
  resetLicenceKabinet,
  setdeviceKabinet,
  resetdeviceKabinet,  
  setKabinetSearch
} = searchbarSlice.actions;

export default searchbarSlice.reducer;