const filter = [
  {text: 'Названию', value: 'name'},
  {text: 'Профилю', value: 'profile'},
  {text: 'Без профиля', value: 'woprofile', disableEdit: true}
];

const sort = [
  {text: 'По названию', value: 'name', type: 'asc', default: true},
  {text: 'По названию', value: 'name', type: 'desc'}
];

export { filter, sort };
