import React, {useEffect, useState} from "react";
import { styled, alpha } from "@mui/material/styles";
import { InputBase, InputLabel, FormControl, Select, MenuItem, Box, IconButton } from "@mui/material";
import { ArrowDropDown, ArrowDropUp, FilterAltOutlined, FilterAltOffOutlined, Clear } from "@mui/icons-material";

import IconStyled from "./styled/IconButtonStyled.js";
import InputBaseStyled from "./styled/InputBaseStyled.js"



const Edit = ({field, disabled, setFilterValue}) => {
  
  const [value, setValue] = useState('');
  const [filtered, setFiltered] = useState(false);
  const [edited, setEdited] = useState(false);

  useEffect( () => {
    setValue('');
    setFiltered(false);
    setFilterValue('');
    setEdited(false);
  }, [field]);

  
  const getValueIcon = () => edited ? <FilterAltOutlined /> : <FilterAltOffOutlined />;
  const getDisabledIcon = () => {
    if(value) return false;
    return !filtered;
  }

  const onFilterClick = () => {
    if(edited) {
      setFilterValue(value);
    } 
    else {
      setValue('');
      setFilterValue('');
    }
    setFiltered(edited);
    setEdited(false);
  };

  
  return (
    <>
      <InputBaseStyled
        placeholder="Поиск…"
        disabled={disabled || !field}
        value={value}
        onChange={ (e) => {
          setValue(e.target.value);
          setEdited(true);
        }}
        onKeyDown={ (e) => {
          if(e.key === 'Enter') onFilterClick()
        }}
        inputProps={{ 'aria-label': 'filter' }}
      />
      <IconStyled
        disabled={getDisabledIcon()}
        onClick={onFilterClick}>
        {getValueIcon()}
      </IconStyled>
    </>  
  );
};

export default Edit;
