import { styled } from "@mui/material/styles";
import { InputBase } from "@mui/material";

const InputBaseStyled = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  padding: theme.spacing(0, 1),
  flexGrow: 1,
  '&.Mui-disabled' : {
    '& input': {
      textFillColor: '#7bb1e3'
    },
    '& input::placeholder' : {
      textFillColor: 'white'
    }
    
  }
}));

export default InputBaseStyled;