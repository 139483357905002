import React, { useEffect } from "react";
import styles from "./Transition.module.css";
import { CSSTransition, TransitionGroup } from "react-transition-group";

const renderRight = (right, rightRef) => 
          right ? <CSSTransition
                    classNames="tgitem"
                    nodeRef={rightRef}
                    timeout={{appear: 500, enter: 500, exit: 0}}
                  >
                    {right}
                  </CSSTransition> : null;


const Transition = ({left, right, rightRef}) => {

  return (
    <TransitionGroup component={null}>
      
      <CSSTransition
        classNames="tgitem"
        timeout={{appear: 500, enter: 500, exit: 0}}
      >
          {left}
      </CSSTransition>

      { renderRight(right, rightRef) }

    </TransitionGroup>
  )
};

export default Transition;