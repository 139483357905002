import { Avatar, Button, Grid, IconButton, InputAdornment, Paper, Stack, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useNavigate} from 'react-router-dom';
import { TextField, CheckBox } from "components/forms";
import React, { useState } from "react";
import styles from "./LoginForm.module.css";
import { HidingError } from "components/errors";

import Chb from '@mui/material/Checkbox';
import LoginIcon from '@mui/icons-material/Login';
import { Visibility, VisibilityOff, AccountCircle, LockOutlined, Password} from "@mui/icons-material";
import { /*useController, */useForm } from "react-hook-form";
import { testEmail } from "utils/regexp";
import { registerUser, loginUser } from "store/auth/authActions";
import { clearError, setShowError } from "store/auth/authSlice";
import { useDispatch, useSelector } from 'react-redux';
import { withAuthService } from "components/hoc";

const LoginForm = ({ authService: service, gotoRegistration}) => {
    
    const [showPassword, setShowPassword] = useState(false);
    //const [name, setName] = useState('name');
    
    const handleClickShowPassword = () => setShowPassword(!showPassword);
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const { control,
            handleSubmit,
            formState: {isSubmitting, isDirty, isValid} } = useForm({
        defaultValues: {
            name: '',
            password: '',
            remember: true
        },
        mode: 'onChange'
    });
    const dispatch = useDispatch();
    const {loading, error, showError} = useSelector(
        (state) => state.auth
    )

    const submitForm = (data) => {
        dispatch(loginUser({service, data}));
    };

    
    const hideError = () => {
        dispatch(setShowError(false));
        setTimeout( () => dispatch(clearError()), 1000);
    };

    const navigate = useNavigate();

    return (
        <form onSubmit={handleSubmit(submitForm)}>
            <HidingError open={showError} message={error} onClose={hideError}/>
            <Grid className="auth-form">
                <Paper elevation={10} className={styles.paper}>
                    <Grid align='center'>
                        <Avatar className={styles.avatar} sx={{ width: 56, height: 56 }}>
                            <LockOutlined />
                        </Avatar>
                        <h2>Вход в личный кабинет</h2>
                    </Grid>
                    <TextField 
                        control={control}
                        name="name"
                        rules={{ validate: testEmail }}
                        label="Имя пользователя"
                        sx={{marginTop: 1}}
                        variant="standard"
                        placeholder="Введите имя пользователя" 
                        helperText="Некорретный адрес электронной почты"
                        fullWidth
                        InputProps={{
                            startAdornment: (
                              <InputAdornment position="start" className="text-icon">
                                <AccountCircle />
                              </InputAdornment>
                            ),
                        }}
                        required />                   
                    <TextField 
                        control={control}
                        label="Пароль"
                        name="password"
                        rules={{ required: true }}
                        sx={{marginTop: 1}}
                        variant="standard"
                        placeholder="Введите пароль" 
                        type={showPassword ? "text" : "password"}
                        fullWidth
                        required
                        helperText="Некорретный пароль"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start" className="text-icon">
                                  <Password />
                                </InputAdornment>
                            ),
                            endAdornment: (
                                <InputAdornment position="end" className="text-icon">
                                    <IconButton className="text-icon"
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword} 
                                        onMouseDown={handleMouseDownPassword}
                                        edge="end" >
                                            {showPassword ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                </InputAdornment>
                            )
                        }} />  
                    <CheckBox
                        controlForm={control}
                        control={<Chb defaultChecked />}
                        sx={{marginTop: 1}}
                        name="remember"
                        label="Запомнить меня"
                    />    
                    <LoadingButton 
                        variant="contained"
                        disabled={!isValid}
                        startIcon={<LoginIcon />}
                        loadingPosition="start"
                        loading={loading}
                        sx={{marginTop: 2}}
                        fullWidth
                        type="submit" >                                                                             
                        <span>Войти</span>
                    </LoadingButton>
                    <Typography align="center">
                        <Button variant="text" color="secondary" size="small">Напомнить пароль</Button>
                    </Typography>
                    <Stack 
                        direction="row"
                        alignItems="center"
                        sx={{
                            marginTop: 2,
                            backgroundColor: 'rgb(238, 238, 238)',
                            padding: 1
                        }}
                        justifyContent="space-between" >
                        <Typography color="secondary">Нет личного кабинета?</Typography>
                        <Button 
                            variant="outlined"
                            color="secondary"
                            onClick={gotoRegistration}
                        >
                                Создать
                        </Button>                        
                    </Stack>                
                </Paper>
            </Grid>
        </form>

        
    );
};

export default withAuthService(LoginForm);
