import { useDispatch, useSelector } from 'react-redux';
import { NavLink, Outlet } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { refreshToken } from "store/auth/authActions";
import { withAuthService } from "components/hoc";

const AdminRoute = ({authService: service}) => {
    
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { userInfo, rememberUser } = useSelector(state => state.auth);

    useEffect(() => { 
        if (!userInfo && !rememberUser) {
          navigate('/login'); 
        }
        else if(!userInfo && rememberUser) {
          dispatch(refreshToken({service}));
        }
    }, [userInfo, navigate] );

    return <Outlet />;
};

export default withAuthService(AdminRoute);