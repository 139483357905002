import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { Mutex } from 'async-mutex';
import { isObjectLike  } from 'lodash';
import { authService } from 'index';
import { setToken, logout } from 'store/auth/authSlice';
import { isDevelopment } from 'utils/environment';

//const dataUrl = "https://test.restart-kassa.ru/api/kabinet/v1";
//const dataUrl = 'http://localhost:3001/api/v1';

const mainUrl = isDevelopment() ? 'http://localhost:3001' : 'https://test.restart-kassa.ru';
const dataUrl = isDevelopment() ? 'http://localhost:3001/api/androidkassa/v1' : 'https://test.restart-kassa.ru/api/androidkassa/v1';

const mutex = new Mutex();

const baseDataQuery = fetchBaseQuery({ 
    baseUrl: dataUrl,
    prepareHeaders: (headers, { getState }) => {
        const token = getState().auth.accessToken;
        if(token) {
            headers.set('authorization', `Bearer ${token}`);
            return headers;
        }
    }
});

const toHttpParams = (obj) => {
    return Object.entries(obj)
            .map( ([key, value]) => 
                encodeURIComponent(key) + '=' + encodeURIComponent(isObjectLike(value) ? JSON.stringify(value) : value)
            )
            .join('&');  
};

const baseQueryWithReauth = async (args, api, extraOptions) => {
    
    // wait until the mutex is available without locking it
    await mutex.waitForUnlock();
    let result = await baseDataQuery(args, api, extraOptions)
    
    if (result.error && result.error.status === 401) {
        if(!mutex.isLocked()) {
            const release = await mutex.acquire();
            try {
                const refreshResult = await authService.refreshToken();
                if(refreshResult && refreshResult.success) {
                    api.dispatch(setToken(refreshResult.body.accessToken));
                    result = await baseDataQuery(args, api, extraOptions);
                }
                else api.dispatch(logout());
            }
            catch(err) {
                api.dispatch(logout());
            }
            finally {
               // release must be called once the mutex should be released again.
                release(); 
            }
        }
        else {
            await mutex.waitForUnlock();
            result = await baseDataQuery(args, api, extraOptions);
        }
        
    } 
    return result;
};

export const dataApi = createApi({
    reducerPath: 'dataApi',
    baseQuery: baseQueryWithReauth,
    endpoints: (builder) => ({
        getApps: builder.query({
            query: (arg) => ({
                url: '/apps',
                params: toHttpParams(arg)
            })
        }),
        getDevices: builder.query({ 
            query: (arg) => ({ 
                url: '/devices',
                params: toHttpParams(arg)
            })
        }),
        getProfiles: builder.query({ 
            query: (arg) => ({ 
                url: '/profiles',
                params: toHttpParams(arg)
            })
        }),
    })
});

export {
    mainUrl
};

export const { 
    useGetAppsQuery,
    useGetDevicesQuery,
    useGetProfilesQuery
} = dataApi;