import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { ClientJS } from 'clientjs';


export const registerUser = createAsyncThunk(
    'auth/register',
    async ({service, data}, {rejectWithValue}) => {
        try {
            return await service.registerUser(data);
        }
        catch(error) {
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message);
            } 
            else {
                return rejectWithValue(error.message);
            }
        }
    }
    

);

export const loginUser = createAsyncThunk(
    'auth/login',
    async ({service, data}, {rejectWithValue}) => {
        try {
            const answer = await service.loginUser(data);
            localStorage.setItem('rememberUser', data.remember);    
            return answer;
        }
        catch (error) {
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message);
            } 
            else {
                return rejectWithValue(error.message);
            }
        }
        
    }
);

export const refreshToken = createAsyncThunk(
    'auth/refresh',
    async ({service}, {rejectWithValue}) => {
        try {
            return await service.refreshToken();
        }
        catch (error) {  
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message);
            } 
            else {
                return rejectWithValue(error.message);
            }        
        }
    }
);