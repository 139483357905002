import React, { useEffect, useState } from "react";
import styles from "./MainPage.module.css";
import { Header, Sidebar } from "components/layout";
import { MenuItem, SubMenu } from "react-pro-sidebar";
import { logout } from "store/auth/authSlice";
import { FullScreenLoader } from "components/loaders";
import { FullScreenError } from "components/errors";
import { Logout, RemoveModeratorOutlined, TextSnippetOutlined, AccountCircleOutlined,
        TabletAndroidOutlined, AppsOutlined, MenuOutlined} from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Routes, Route, NavLink} from 'react-router-dom';
import { IconButton } from "components/buttons";

const Profile = () => {
    return (
        <h1 className="header">Профиль</h1>
    );
};

const Appstore = () => {

    //const t = useGetAppsQuery('getApps');
    

    return (
        <h1 className="header">Магазин приложений</h1>
    );
};
const Licences = () => {
    return (
        <h1 className="header">Лицензии</h1>
    );
};
const DevicePage = () => {
    return (
        <h1 className="header">Устройства</h1>
    );
};

const MainPage = () => {

    const [collapsedBar, setCollapsedBar] = useState(false);

    const {userInfo, accessToken, rememberUser, error} = useSelector(state => state.auth);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => { 
        if(!accessToken && !rememberUser) {
            dispatch(logout());
        }
    }, [accessToken]);

    const onLogout = () => {
        dispatch(logout());
    };

    const onKabinet = () => {
        navigate("/kabinet");
    };

    

    if(error) {
        return <FullScreenError />;
    }

    if (!accessToken) {
        return <FullScreenLoader />;
    }

    const headerItems =
        <>
            <IconButton
                text="Перейти в кабинет"
                iconCmp={<RemoveModeratorOutlined />}
                handler={onKabinet}
            />
            <IconButton
                text="Выйти из админки"
                iconCmp={<Logout />}
                handler={onLogout}
            />
        </>;

    return (
        <div className={styles.wrapper}>
            <Sidebar
                collapsed={collapsedBar}    
            >
                <MenuItem
                    icon={<MenuOutlined />}
                    onClick={ () => setCollapsedBar(!collapsedBar) }
                >
                    <h1>Админка</h1> 
                </MenuItem>
                
                <MenuItem
                    icon={<  AccountCircleOutlined />}
                    component={<NavLink to="clients" className="link" />}
                >
                    Управление клиентами
                </MenuItem>
                <MenuItem 
                    icon={< AppsOutlined/>}
                    component={<NavLink to="appstore" className="link" />}
                > 
                    Магазин приложений 
                </MenuItem>
                <MenuItem 
                    icon={< TabletAndroidOutlined />}
                    component={<NavLink to="devices" className="link" />}
                >
                    Управление кассами
                </MenuItem>
                <MenuItem
                    icon={< TextSnippetOutlined />}
                    component={<NavLink to="licences" className="link" />}
                >
                    Генератор лицензий
                </MenuItem>
            </Sidebar>
            <div className={styles.contentWrapper}>
                <Header
                    user={userInfo?.userName}
                    items={headerItems}
                />
                <section className={styles.content}>
                    <Routes>
                        <Route path="profile" element={<Profile />} />
                        <Route path="devices" element={<DevicePage />} />
                        <Route path="appstore" element={<Appstore />} />
                        <Route path="licences" element={<Licences />} />
                    </Routes>
                </section>
            </div>
        </div>
        
    );
};

export default MainPage;