import { configureStore } from '@reduxjs/toolkit';
import { dataApi } from 'services/data/DataService';
import authReducer from 'store/auth/authSlice';
import deviceReducer from 'store/device/deviceSlice';
import appReducer from 'store/app/appSlice';
import searchbarReducer from 'store/searchbar/searchbarSlice';

export const store = configureStore({
  reducer: {
    auth: authReducer,
    device: deviceReducer,
    app: appReducer,
    searchbar: searchbarReducer,
    [dataApi.reducerPath]: dataApi.reducer
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(dataApi.middleware)
});
