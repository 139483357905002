import React from "react";
import { Sidebar as Sidebarpro, Menu, MenuItem, sidebarClasses } from "react-pro-sidebar";


import styles from "./Sidebar.module.css";
import { Link } from 'react-router-dom';

const Sidebar = ({children, ...props}) => {

    /*const renderItems = (items) => {       
            return items.map(item => {
                return <MenuItem
                         {...item.data}
                        > 
                            {item.value}
                        </MenuItem>;
            });
    };*/

    return (
        <Sidebarpro 
            {...props}
            className={styles.wrapper}
            rootStyles={{
                width: 300,
                [`.${sidebarClasses.container}`]: {
                    backgroundColor: '#071627',
                }
            }}
            
        >
            <Menu
                menuItemStyles={{
                    root: {
                        color: 'rgb(210, 230, 250)',
                        fontFamily: "Montserrat",
                        fontWeight: 600
                    },
                    /*button: ({active}) => {
                        if(active) return {
                            color: 'black',
                            backgroundColor: 'white'
                        }
                                                   
                    },*/
                    subMenuContent: {
                        backgroundColor: '#071627'
                    },
                    label: {
                        fontFamily: "Montserrat",
                        fontSize: '13px',
                        fontWeight: 500
                    }
                }}
            >
                {children}                    
            </Menu>
            
        </Sidebarpro>
    );
};

export default Sidebar;