import {  Card, CardActions, CardContent, ListItem, ListItemButton, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import React from "react";
import Box from '@mui/material/Box';
import AndroidIcon from '@mui/icons-material/Android';
import PhonelinkSetupOutlinedIcon from '@mui/icons-material/PhonelinkSetupOutlined';
import styles from "./DeviceItem.module.css";


import {Apps, SettingsOutlined, TextSnippetOutlined, SyncAltOutlined} from '@mui/icons-material/'

const DeviceItem = ({id, model, hardId, hardType, minSdk, imei1, imei2,
     osVersion, license, lastConnect, onSelect, selected, activeDetails}) => { 

    const getLicenseText = () => {
        if(!license) return 'Лицензии нет';
    };

    const getConnectionText = () => {
        if(!lastConnect) return 'Связи с устройством ещё не было';
    };

    return (
        <ListItemButton
            className="pagelist-item"
            sx={{pr:1, pb: 1, pl:0, pt: 0, flexGrow: 1}}
            disableRipple
            disableTouchRipple
            selected={selected}
        >   
            <Card sx={{width:'100%'}}> 
                <CardContent sx={{cursor: 'auto'}}>
                    <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}> 
                        <Box className={styles.itemIcon}>
                            <PhonelinkSetupOutlinedIcon color='secondary' sx={{ fontSize: 28 }}/>
                        </Box>
                        <Box sx={{display: 'flex', flexDirection: 'column', flexGrow: 1, mr: 1, }}>
                            <Box sx={{ color: 'text.primary', fontSize: 20}}>
                                {model} {hardType} {id}
                            </Box>
                            <Typography sx={{ color: 'text.primary', fontSize: 13, fontStyle: 'italic' }}>
                                {getLicenseText()}
                            </Typography>
                            <Typography sx={{ color: 'text.primary', fontSize: 13, fontStyle: 'italic' }}>
                                {getConnectionText()}
                            </Typography>   
                        </Box>
                        
                        <Box sx={{display: 'flex', flexDirection: 'column', ml: 1, flexGrow: 1, alignItems: 'flex-end'}}>
                            <Box sx={{ color: 'text.secondary', fontSize: 13, lineHeight: '24px'}}>
                                HID: {hardId}
                            </Box>
                            <Box sx={{ color: 'text.secondary', fontSize: 13, lineHeight: '24px'}}>
                                IMEI 1: {imei1} IMEI2: {imei2}
                            </Box>
                            <Box sx={{display: 'flex', alignitems: 'baseline'}}>
                                <AndroidIcon sx={{color: "#36a958", mr: 1}}/>
                                <Box sx={{ color: 'text.secondary', fontSize: 13, display: 'flex', alignItems: 'center'}}>
                                    {osVersion} MinSDK: {minSdk}
                                </Box>
                            </Box>
                        </Box>
                    </Box>                                                  
                </CardContent>
                <CardActions className={styles.itemContainer}>
                    <ToggleButtonGroup 
                        variant="text"
                        color="secondary"
                        size="small"
                        value={activeDetails.value}
                        onChange={(event, value) => onSelect({model, hardType, id}, {value, text: event.target.textContent})}
                        exclusive
                        aria-label="Small button group"
                    >
                        <ToggleButton value="apps" className={styles.itemAction}>
                            <Apps />
                            Приложения
                        </ToggleButton>
                        <ToggleButton value="lics" className={styles.itemAction}>
                            <TextSnippetOutlined />
                            Лицензии
                        </ToggleButton>
                        <ToggleButton value="log" className={styles.itemAction}>
                            <SyncAltOutlined />
                            Лог
                        </ToggleButton>
                        <ToggleButton value="prefs" className={styles.itemAction}>
                            <SettingsOutlined />
                            Настройки
                        </ToggleButton>
                    </ToggleButtonGroup>
                        
                </CardActions>
            </Card>
        </ListItemButton>
    );
};

export default DeviceItem;