import { CloudUpload } from "@mui/icons-material";
import { Button, Tooltip  } from "@mui/material";
import { styled } from '@mui/material/styles';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

const UploadButton = ({text, tip, handler, color}) => {
  return (
    <Tooltip title={tip}>
      <Button
        component="label"
        color={color}
        role={undefined}
        variant="outlined"
        tabIndex={-1}
        startIcon={<CloudUpload />}
      >
        {text}
        <VisuallyHiddenInput
          type="file"
          onChange={(event) => handler(event)}
          multiple
        />
      </Button>
    </Tooltip>
  );  
};

export default UploadButton;