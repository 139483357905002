import { styled } from "@mui/material/styles";
import { IconButton } from "@mui/material";

const IconButtonStyled = styled(IconButton)(({ theme }) => ({
  padding: theme.spacing(0, 1),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: 'inherit',
  '&.Mui-disabled' : {
    color: '#7bb1e3'
  }
}));

export default IconButtonStyled;