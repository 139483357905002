import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    selectedDevice: null,
    activeDetails: null,
    pagination: {
        start: 0,
        limit: 10,
        page: 1
    }
};

const deviceSlice = createSlice({
    name: 'device',
    initialState,
    reducers: {
        setSelectedDevice: (state, {payload}) => { state.selectedDevice = payload; },
        setActiveDetails: (state, {payload}) => { state.activeDetails = payload; },
        setPagination: (state, {payload}) => { state.pagination = payload; },
        resetDetails: (state) => { state.activeDetails = null; },
        resetPagination: (state) => { state.pagination = {start: 0, limit: 10, page: 1} },
        resetDevice: (state) => {
            state.selectedDevice = null;
            state.activeDetails = null;
        }
    }
});

export const {
    setSelectedDevice,
    setActiveDetails,
    setPagination,
    resetDetails,
    resetDevice,
    resetPagination
} = deviceSlice.actions;

export default deviceSlice.reducer;