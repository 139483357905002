import { createSlice } from '@reduxjs/toolkit';
import { jwtDecode } from 'jwt-decode';
import { registerUser, loginUser, refreshToken } from 'store/auth/authActions';

const rememberUser = localStorage.getItem('rememberUser') === 'true';

const initialState = {
    rememberUser,
    loading: false,
    userInfo: null,                 // for user object
    accessToken: null,                // for storing the JWT
    //refreshToken: null,                // for storing the JWT
    error: null,
    showError: false,
    success: false,                 // for monitoring the registration process.
};

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        clearError: (state, action) => {
            state.error = null;
        },
        setShowError: (state, {payload}) => {
            state.showError = payload;
        },
        logout: (state) => {
            localStorage.removeItem('rememberUser');
            state.userInfo = null;
            state.error = null;
            state.loading = false;
            state.accessToken = null;
            state.success = false;
            state.rememberUser = false;
        },
        setToken: (state, action) => {
            state.accessToken = action.payload; 
            state.userInfo = jwtDecode(state.accessToken);
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(loginUser.pending, (state) => {
                state.loading = true;
                state.error = null;
                state.showError = false;
            })
            .addCase(loginUser.fulfilled, (state, {payload}) => {
                state.loading = false;
                state.success = true;
                state.userInfo = jwtDecode(payload.body.accessToken);
                state.accessToken = payload.body.accessToken;
            })
            .addCase(loginUser.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
                state.showError = true;
            })
            .addCase(registerUser.pending, (state) => {
                state.loading = true;
                state.error = null;
                state.showError = false;
            })
            .addCase(registerUser.fulfilled, (state, action) => {
                state.loading = false;
                state.success = true;
            })
            .addCase(registerUser.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
                state.showError = true;
            })
            .addCase(refreshToken.pending, (state, action) => {
                state.loading = true;
                state.error = null;
            })            
            .addCase(refreshToken.fulfilled, (state, {payload}) => {
                state.loading = false;
                state.success = true;
                state.userInfo = jwtDecode(payload.body.accessToken);
                state.accessToken = payload.body.accessToken;
            })
            .addCase(refreshToken.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
                state.rememberUser = false;
            });
    }
});

export const {
    clearError, setShowError, logout, setToken
} = authSlice.actions;

export default authSlice.reducer;
