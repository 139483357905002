import React, {useEffect, useState, useLayoutEffect} from "react";

import { FormControl, Select, MenuItem, Box, IconButton } from "@mui/material";
import { ArrowDropDown, ArrowDropUp, Clear } from "@mui/icons-material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from './SearchField.module.css';

import BoxStyled from "./styled/BoxStyled";
import Edit from "./Edit";
import Sort from "./Sort";
import IconButtonStyled from "./styled/IconButtonStyled";

const SearchBar = ( {className, defaultSortValue, onSearchChanged, data: {filter, sort, current={}} = {} }) => {
  
  const [opened, setOpened] = useState(false);

  const {value1='', field='', sort: currentSort=''} = current;
  
  const onValueChanged = (value) => onSearchChanged('value', value);
  const onFilterChanged = (value) => onSearchChanged('field', value);
  const onSortChanged = (value) => onSearchChanged('sort', value);


  const getTypeIcon = () => {
    if(field) {
      return <IconButtonStyled onClick={ ()=> onFilterChanged('')}>
                <Clear />
              </IconButtonStyled> ;  
    } 
    return opened ? <IconButtonStyled onClick={ ()=>setOpened(false)}>
                        <ArrowDropUp />
                    </IconButtonStyled> : <IconButtonStyled onClick={ ()=>setOpened(true)}>
                                      <ArrowDropDown />
                                    </IconButtonStyled>;
  };

  const getDisabledEdit = () => {
      const item = filter.find( (item) => item.value === field);
      if(item) return item.disableEdit ?? false;
      else return false;
  };
  
  const renderItems = (items) => { 
    if(items && items.length) { 
      return items.map( (item) => <MenuItem key={item.value} value={item.value}>{item.text}</MenuItem>);
    }
    else return [];
  };

  return (
    <Box 
      className={`${className} ${styles.wrapper}`}>
        <BoxStyled 
          className={styles.type}>
            <FormControl
              variant="standard" 
              sx={{width:'100%', display: 'flex', flexDirection: 'row', px: 1}}>
              <Select
                value={field}
                autoWidth={false}
                displayEmpty
                open={opened}
                onOpen={ () => setOpened(true)}
                onClose={ () => setOpened(false)}
                onChange={ (e) => onFilterChanged(e.target.value) }
                inputProps={{ 'aria-label': 'Without label', sx: {pr:'12px !important'} }}
                IconComponent={() => null}
                className={styles.selectfield} 
                renderValue={(selected) => { 
                  if (selected && (selected.length !== 0)) { 
                    const item = filter.find( (item) => item.value === selected);
                    if(item) return item.text;
                  }
                  return <span className={styles.placeholder}> Фильтр по...</span>;
                }}   
              >
                {renderItems(filter)}
              </Select>

            </FormControl>
            {getTypeIcon()}
            
        </BoxStyled>
        <BoxStyled className={styles.value}>
          <Edit 
            field={field}
            disabled={getDisabledEdit()}
            setFilterValue={onValueChanged}/>
        </BoxStyled>
        <BoxStyled className={styles.sort}>
          <Sort 
            data={sort}
            current={currentSort}
            sortChanged={onSortChanged}
            defaultValue={defaultSortValue}/>
        </BoxStyled>
    </Box>

  );
};

export default SearchBar;