import { Box, Pagination } from "@mui/material";
import { useEffect, useState } from "react";


const Paginator = ({changePage, page, total, pageSize}) => {

    return (
        <Box justifyContent="center" alignItems="center" display="flex" overflow="visible"
            sx={{mt:1}}
        >
            <Pagination 
                showFirstButton 
                showLastButton
                color="primary"
                page={page}
                count={Math.ceil(total / pageSize)}
                onChange={changePage}
            />
        </Box>
    );
};

export default Paginator;