import { IconButton as IButton, Tooltip  } from "@mui/material";

const IconButton = ({text, iconCmp, handler}) => {
    return (
        <Tooltip title={text}>
            <IButton 
                color="inherit"
                onClick={handler}
            >
                {iconCmp}
            </IButton>
        </Tooltip>
    );  
};

export default IconButton;