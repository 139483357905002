import React, { useEffect, useState } from "react";
import IconButtonStyled from "./styled/IconButtonStyled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUpShortWide, faArrowDownShortWide, faArrowDownUpAcrossLine } from "@fortawesome/free-solid-svg-icons";
import { Menu, MenuItem } from "@mui/material";
import { set } from "react-hook-form";


const getDefaultSort = (data) => {
  if(!Array.isArray(data)) return {field: null, type: null};
  const value = data.find( (item) => item.default === true);
  if(value) return {field: value.value, type: value.type};
  else return {field: null, type: null};
}

const Sort = ({sortChanged, current, data}) => {
  
  const [anchorEl, setAnchorEl] = useState(null);
  const [type, setType] = useState( () => getDefaultSort(data) );
  
  useEffect ( () => {
    if(!current) {
      const defaultSort = getDefaultSort(data);
      sortChanged(defaultSort);
    }
  }, []);

  const getIcon = () => { 
    switch(current.type) {
      case 'asc': return <FontAwesomeIcon icon={faArrowUpShortWide} />;
      case 'desc': return <FontAwesomeIcon icon={faArrowDownShortWide} />;
      default: return <FontAwesomeIcon icon={faArrowDownUpAcrossLine} />;
    }
  };

  const getItemIcon = (type) => {
    if(type !== 'asc') return <FontAwesomeIcon className='menu-icon' icon={faArrowDownShortWide} />;
    else return <FontAwesomeIcon className='menu-icon' icon={faArrowUpShortWide} />;
  };

  const getSelected = (item, current) => {
    if(current) { 
      return (current.field === item.value) && (current.type === item.type);
    }
    else { 
      return item.default;
    }
  };

  const renderItems = (data, current) => { 
      return data.map( (item) => (
        <MenuItem
          key={`${item.text}-${item.type}`}
          selected={ getSelected(item, current) }
          onClick={ (e) => itemSelect(item.value, item.type)}
        >
          {getItemIcon(item.type)}
          {item.text}
        </MenuItem>  
      ));
  };

  const itemSelect = (field, type) => {
    sortChanged({field, type})
    setAnchorEl(null);
  };

  return (
    <>
      <IconButtonStyled
        onClick={ (e)=> setAnchorEl(e.currentTarget)}>
        {getIcon()}
      </IconButtonStyled>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={ () => setAnchorEl(null) }
        sx={{
          '& .MuiPaper-root': {
            marginTop: '4px',
            color: 'rgb(55, 65, 81)'
          }
        }}>
          {renderItems(data, current)}
      </Menu>
    </>
    
  );
};

export default Sort;