import React, { useState, useRef, useEffect } from "react";
import styles from "./AppPage.module.css";
import { useGetAppsQuery } from "services/data/DataService";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedApp, resetApp, setPagination } from "store/app/appSlice";
import Paginator from "components/paginator/Paginator";
import { Button, List, Paper, Container, Stack } from "@mui/material";
import { Transition } from "components/layout";
import AppItem from "./AppItem";
import AppDetails from "./AppDetails";
import { UploadButton } from "components/buttons";

const AppPage = ({setActiveToolbar}) => {

    const dispatch = useDispatch();
    const detailsRef = useRef(null);

    const { selectedApp, activeDetails, pagination } = useSelector(state => state.app);
    const { field='', value='', sort='' } = useSelector(state => state.searchbar).appKabinet; 
    const { data, isError,  isFetching, refetch } = useGetAppsQuery({start: pagination.start, limit: pagination.limit,
                                                                      sort: [sort], filter: [{field, value}]});

    useEffect( () => setActiveToolbar("app"));

    const changePage = (event, page) => {
        const start = (page - 1) * pagination.limit; 
        dispatch(setPagination({...pagination, start: start, page: page}));
        dispatch(resetApp());
    };

    if(isFetching) return <div>Загрузка...</div>;
    if(isError) return <div>Ошибка!</div>;

    const onItemSelect = (item) => dispatch(setSelectedApp(item)); 
    
    const isItemSelected = (item) => item?.id===selectedApp?.id;

    const renderItems = data.body.items.map((item, index) => {
        return  <AppItem
                    key={item.id}
                    selected={isItemSelected(item)}
                    onSelect={onItemSelect}
                    { ...item} 
                />;             
    });

    const details = selectedApp ? <div className="right" ref={detailsRef}>
                                        <AppDetails
                                            item={selectedApp}
                                            details={activeDetails}
                                        />
                                    </div> : null   

    const body = <div className="left">
                    <div className="pagelist">
                        <div className="pagelist-body">
                            <List >
                                {renderItems}
                            </List>
                        </div>
                        <Paginator 
                            changePage={changePage}
                            total={data.body.total}
                            pageSize={pagination.limit}
                            page={pagination.page}
                        />
                    </div>
                </div>;

    return (    
        <div className="page">
            <Paper elevation={1} sx={{flexShrink: 0, marginBottom: '4px', py: 1}}>
                <Stack direction="row" spacing={1} className="toolbar">
                    <UploadButton 
                        tip="Загрузить приложение в кабинет"
                        text="Добавить"
                        color="info"
                        handler={()=>console.log("Upload handler")} />
                </Stack>
            </Paper>
            <div className="page-content">
                <Transition
                    left = { body }
                    right = { details }
                    rightRef = { detailsRef }
                />
            </div>
        </div>
    );

};

export default AppPage;