import React, { useState, useRef, useEffect } from "react";
import styles from "./DevicePage.module.css";
import DeviceItem from "./DeviceItem";
import { List } from "@mui/material";
import { useGetDevicesQuery } from "services/data/DataService";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedDevice, setActiveDetails, resetDevice, setPagination } from "store/device/deviceSlice";
import DeviceDetails from "./DeviceDetails";
import Paginator from "components/paginator/Paginator";
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { Toolbar, Transition } from "components/layout";


const DevicePage = ({setActiveToolbar}) => {

    const dispatch = useDispatch();
    const detailsRef = useRef(null);

    const changePage = (event, page) => {
        const start = (page - 1) * pagination.limit;
        dispatch(setPagination({...pagination, start: start, page: page}));
        dispatch(resetDevice());
    };
    
    useEffect( () => setActiveToolbar("device"));

    const { selectedDevice, activeDetails, pagination } = useSelector(state => state.device);
    const { field='', value='', sort='' } = useSelector(state => state.searchbar).deviceKabinet; 
    const { data, isError,  isFetching, refetch } = useGetDevicesQuery({start: pagination.start, limit: pagination.limit, 
                                                                            sort: [sort], filter: [{field, value}]});
    
    if(isFetching) return <div>Загрузка данных...</div>;
    if(isError) return <div>Ошибка!</div>;


    const onItemSelect = (item, details) => {
        if(details.value) {
            dispatch(setSelectedDevice(item));
            dispatch(setActiveDetails(details));
        }
        else {
            dispatch(resetDevice(item));
        }
    };

    const isItemSelected = (item) => item?.id===selectedDevice?.id;
    const isActiveDetails = (id) => {
        if(id===selectedDevice?.id) return activeDetails;
        else return '';
    };

    const renderItems = data.body.items.map((item, index) => {
        return  <DeviceItem 
                    key={item.id}
                    selected={isItemSelected(item)}
                    onSelect={onItemSelect}
                    activeDetails={isActiveDetails(item.id)}
                    { ...item} 
                />;
    });


    const details = selectedDevice ? <div className="right" ref={detailsRef}>
                                        <DeviceDetails
                                            item={selectedDevice}
                                            details={activeDetails}
                                        />
                                    </div> : null   

    

    const body = <div className="left">
                    <div className="pagelist">
                        <div className="pagelist-body">
                            <List >
                                {renderItems}
                            </List>
                        </div>
                        <Paginator 
                            changePage={changePage}
                            total={data.body.total}
                            pageSize={pagination.limit}
                            page={pagination.page}
                        />
                    </div>
                </div>;

    return (    
        <div className="page">
            <div className="page-content">
                <Transition
                    left = { body }
                    right = { details }
                    rightRef = { detailsRef }
                />
            </div>
        </div>
    );
   
};

export default DevicePage;

