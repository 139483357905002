import React, { useEffect, useState } from "react";
import * as licence from "../LicencePage/SearchBar";
import * as device from "../DevicePage/SearchBar";
import * as profile from "../ProfilePage/SearchBar";
import * as app from "../AppPage/SearchBar";
import styles from "./ToolbarManager.module.css";
import { SearchBar } from "components/forms";
import { useDispatch, useSelector } from "react-redux";
import { setKabinetSearch } from "store/searchbar/searchbarSlice";

const getSearchBarData = (type) => {
  switch(type) {
    case 'licence': return licence; 
    case 'profile': return profile;
    case 'device': return device;
    case 'app': return app;
    default: return {};
  }
};


const ToolbarManager = ({type}) => {

  const searchData = useSelector( (state) => state.searchbar);
  const dispatch = useDispatch();
  
  const onChanged = (key, value) => {
    dispatch(setKabinetSearch({active: type, key, value}));
  };

  return (
    <>
    {
      (searchData[`${type}Kabinet`]) ?
        <SearchBar
            defaultSortValue={'asc'}
            onSearchChanged={onChanged} 
            data={{filter: getSearchBarData(type).filter, sort: getSearchBarData(type).sort, current: searchData[`${type}Kabinet`]}}
        /> : null
    }
    </>
  );
};

export default ToolbarManager;