const filter = [
  {text: 'Раз', value: 'raz'},
  {text: 'Два', value: 'dva'}
];

const sort = [
  {text: 'По названию', value: 'name', type: 'asc', default: true},
  {text: 'По названию', value: 'name', type: 'desc'}
];

export { filter, sort };
