import React from "react";
import { AppBar, Box, Toolbar, Button, Avatar, Typography, Tooltip  } from "@mui/material";
import styles from "./Header.module.css";
import { AccountCircleOutlined, Logout, AdminPanelSettings } from "@mui/icons-material";

const Header = ({user, items, search}) => {
    
    return (
        
            <AppBar 
                position="sticky"
                className={styles.appbar}
                sx={{mb: 1}}
            >
                <Toolbar className={styles.toolbar}>
                    <Box>{search}</Box>
                    <Box className={styles.body}>
                        <Avatar sx={{ margin: '0 4px' }}>
                            <AccountCircleOutlined />
                        </Avatar>
                        <Typography 
                            sx={{margin: '0 8px 0 4px'}}
                            className={styles.login}
                        >
                            {user}    
                        </Typography>
                        {items}
                    </Box>
                    
                </Toolbar>
            </AppBar>
            
        
    );
};
export default Header;