import React, { useEffect, useLayoutEffect, useState } from "react";

const LicencePage = ({setActiveToolbar}) => {

  useEffect( () => {
      setActiveToolbar('licence');
  }, []);

  return (
      <h1 className="header">Лицензии</h1>
  );
};

export default LicencePage;